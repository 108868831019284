import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'customerRoleSelect',
    'statusSelect',
    'attorneyAdditionalChargeMessage'
  ];

  connect() {
    this.toggleAttorneyAdditionalChargeMessage();
  }

  toggleAttorneyAdditionalChargeMessage() {
    if (!this.hasAttorneyAdditionalChargeMessageTarget) {
      return;
    }

    this.attorneyAdditionalChargeMessageTarget.hidden = !this.billable;
  }

  // See `User#billable?`
  get billable() {
    return ['pending_acceptance_of_terms_and_conditions', 'active'].includes(this.statusSelectTarget.value) &&
      this.customerRoleSelectTarget.value === 'attorney';
  }
}
