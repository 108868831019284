import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'billingTypeRadio',
    'yearlySubscriptionCostInput',
    'overrideBillingContainer'
  ];

  connect() {
    this.toggleYearlySubscriptionCostApplicability();
  }

  toggleYearlySubscriptionCostApplicability() {
    const checkedBillingTypeRadio =
      this.billingTypeRadioTargets.find(radio => radio.checked);

    const yearlySubscriptionCostApplicable =
      checkedBillingTypeRadio !== undefined && checkedBillingTypeRadio.value === 'manual_subscription_cost';

    this.yearlySubscriptionCostInputTarget.required = yearlySubscriptionCostApplicable;
    this.yearlySubscriptionCostInputTarget.disabled = !yearlySubscriptionCostApplicable;

    if (yearlySubscriptionCostApplicable) {
      if (this.yearlySubscriptionCostInputTarget.dataset.oldVal !== undefined) {
        this.yearlySubscriptionCostInputTarget.value =
          this.yearlySubscriptionCostInputTarget.dataset.oldVal;
        delete this.yearlySubscriptionCostInputTarget.dataset.oldVal;
      }
    } else {
      if (this.yearlySubscriptionCostInputTarget.dataset.oldVal === undefined) {
        this.yearlySubscriptionCostInputTarget.dataset.oldVal =
          this.yearlySubscriptionCostInputTarget.value;
        this.yearlySubscriptionCostInputTarget.value = undefined;
      }
    }
  }

  toggleOverrideBilling() {
    const checkedBillingTypeRadio =
      this.billingTypeRadioTargets.find(radio => radio.checked);

    if (checkedBillingTypeRadio.value === 'normal' || checkedBillingTypeRadio.value === 'manual_subscription_cost') {
      this.overrideBillingContainerTarget.classList.remove('hidden')
    } else {
      this.overrideBillingContainerTarget.classList.add('hidden')
    }
  }
}
