import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'custodyArrangementRadio',
    'overnightRangeFieldContainer',
    'nonPrimaryCustodyPartyPossessiveNameRelativeToOtherCaseBasicInfoParties',
    'numChildrenInput',
    'zeroChildrenInfoLabel',
    'custodyArrangementRadiosContainer'
  ];

  connect() {
    this.toggleOvernightRangeFieldContainer();
    this.changeNonPrimaryCustodyParty();
    this.toggleNumberOfChildrenFields();
  }

  changeNonPrimaryCustodyParty() {
    const custodyArrangementRadio =
      this.custodyArrangementRadioTargets.find(car => car.checked);
    let otherPartyPossessiveNameRelativeToOtherCaseBasicInfoPartiesHtml;

    if (custodyArrangementRadio === undefined) {
      otherPartyPossessiveNameRelativeToOtherCaseBasicInfoPartiesHtml = '';
    } else {
      otherPartyPossessiveNameRelativeToOtherCaseBasicInfoPartiesHtml =
        custodyArrangementRadio.dataset.otherPartyPossessiveNameRelativeToOtherCaseBasicInfoPartiesHtml;
    }

    if (otherPartyPossessiveNameRelativeToOtherCaseBasicInfoPartiesHtml === undefined) {
      otherPartyPossessiveNameRelativeToOtherCaseBasicInfoPartiesHtml = '';
    }

    this.nonPrimaryCustodyPartyPossessiveNameRelativeToOtherCaseBasicInfoPartiesTarget.innerHTML =
      otherPartyPossessiveNameRelativeToOtherCaseBasicInfoPartiesHtml;

    this.dispatch('non-primary-custody-party-changed');
  }

  toggleOvernightRangeFieldContainer() {
    const custodyArrangementRadio =
      this.custodyArrangementRadioTargets.find(car => car.checked);

    if(custodyArrangementRadio === undefined ||
      custodyArrangementRadio.value === 'shared') {
      this.overnightRangeFieldContainerTarget.style.display = 'none';
    } else {
      this.overnightRangeFieldContainerTarget.style.display = 'block';
    }
  }

  toggleNumberOfChildrenFields () {
    const zeroChildren = this.numChildrenInputTarget.value === '0';

    this.zeroChildrenInfoLabelTarget.hidden = !zeroChildren;
    this.custodyArrangementRadiosContainerTarget.hidden = zeroChildren;
    this.overnightRangeFieldContainerTarget.hidden = zeroChildren;
  }
}
