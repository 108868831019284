import { Controller } from '@hotwired/stimulus';
import formatCurrency from '../src/format_currency';
import applySubsequentHighlighted from '../src/apply_subsequent_highlighted';

export default class extends Controller {
  static targets = [
    'netWorthResource',
    'netWorthResourceAttrRow',
    'netWorthResourceAttrNetWorthResourceAllocationsInvalidSumWarning',
    'netWorthResourceAttrButton',
    'netWorthResourceAllocationAttrCell',
    'netWorthResourceAllocationAttrInput',
    'partyTotalNetWorthResourceAllocationsAmountOutput',
    'partyEqualizationPaymentAmountOutput',
    'partyTotalNetWorthResourceAllocationsAmountOutputAfterEqualization',
    'highlightable',
    'netWorthResourceAttrRowHeader',
    'equalizationPaymentSummaryLabel',
    'equalizationPaymentAmountField',
    'equalizationPaymentPayingPartyIdField'
  ];

  connect() {
    this.calculate();
    this.equalizationPaymentAmountFieldTarget.dispatchEvent(new Event('change'));
  }

  initializeAllocatingNetWorthResourceAttr(e) {
    const netWorthResourceAttrButton = e.target;
    const netWorthResourceAttrRow =
      this.netWorthResourceAttrRowTargets.find(el => el.contains(netWorthResourceAttrButton));
    const netWorthResourceAllocationAttrCells =
      this.netWorthResourceAllocationAttrCellTargets.filter(el => netWorthResourceAttrRow.contains(el));
    const netWorthResourceAllocationAttrInputs =
      this.netWorthResourceAllocationAttrInputTargets.filter(el => netWorthResourceAttrRow.contains(el));

    netWorthResourceAttrRow.classList.toggle('allocating');
    netWorthResourceAllocationAttrCells.forEach((netWorthResourceAllocationAttrCell) => {
      netWorthResourceAllocationAttrCell.tabIndex =
        netWorthResourceAttrRow.classList.contains('allocating') ? 0 : -1;
    });
    netWorthResourceAllocationAttrInputs.forEach((netWorthResourceAllocationAttrInput) => {
      netWorthResourceAllocationAttrInput.readOnly = netWorthResourceAttrRow.classList.contains('allocating');
      netWorthResourceAllocationAttrInput.tabIndex =
        netWorthResourceAttrRow.classList.contains('allocating') ? -1 : 0;
    });

    this.highlightableTargets
      .filter(el => netWorthResourceAttrRow.contains(el))
      .forEach((highlightable) => {
        if (highlightable.dataset.controller === undefined) {
          highlightable.dataset.controller = '';
        }

        if (netWorthResourceAttrRow.classList.contains('allocating')) {
          highlightable.dataset.controller += ' highlight-if-hover-self-or-subsequent';
        } else {
          highlightable.dataset.controller =
            highlightable.dataset.controller.replace(/\s*\bhighlight-if-hover-self-or-subsequent\b\s*/, '');
        }
      });
    if (!netWorthResourceAttrRow.classList.contains('allocating')) {
      this.calculate(netWorthResourceAttrRow);
    }
  }

  completeAllocatingNetWorthResourceAttr(e) {
    const netWorthResourceAllocationAttrCell = e.currentTarget;
    const netWorthResourceAttrRow =
      this.netWorthResourceAttrRowTargets.find(el => el.contains(netWorthResourceAllocationAttrCell));

    if (!netWorthResourceAttrRow.classList.contains('allocating')) {
      return;
    }

    if (e.keyCode !== undefined) {
      if (e.keyCode === 13 || e.keyCode === 32) { // Enter or Space
        e.preventDefault(); // Prevent page from scrolling if user hit spacebar
      } else {
        return;
      }
    }

    const netWorthResourceAttrButton =
      netWorthResourceAttrRow.querySelector("[data-division-form-target='netWorthResourceAttrButton']");

    const netWorthResourceAllocationAttrCells =
      netWorthResourceAttrRow.querySelectorAll("[data-division-form-target='netWorthResourceAllocationAttrCell']");

    const netWorthResourceAttrRowAllocationAttrInputs =
      netWorthResourceAttrRow.querySelectorAll("[data-division-form-target='netWorthResourceAllocationAttrInput']");

    const selectedNetWorthResourceAllocationAttrInput =
      netWorthResourceAllocationAttrCell.querySelector("[data-division-form-target='netWorthResourceAllocationAttrInput']");

    Array.from(netWorthResourceAttrRowAllocationAttrInputs).forEach((netWorthResourceAllocationAttrInput) => {
      netWorthResourceAllocationAttrInput.value = '';
      netWorthResourceAllocationAttrInput.readOnly = false;
      netWorthResourceAllocationAttrInput.tabIndex = 0;
    });
    selectedNetWorthResourceAllocationAttrInput.value =
      formatCurrency(
        netWorthResourceAttrButton.dataset.amount,
        {
          style: 'decimal',
          useGrouping: false
        }
      );
    selectedNetWorthResourceAllocationAttrInput.dispatchEvent(new Event('change'));

    Array.from(netWorthResourceAllocationAttrCells).forEach((netWorthResourceAllocationAttrCell) => {
      netWorthResourceAllocationAttrCell.tabIndex = -1;
    });

    netWorthResourceAttrRow.classList.remove('allocating');
    this.highlightableTargets
      .filter(el => netWorthResourceAttrRow.contains(el))
      .forEach((highlightable) => {
        if (highlightable.dataset.controller === undefined) {
          highlightable.dataset.controller = '';
        }

        highlightable.dataset.controller =
          highlightable.dataset.controller.replace(/\s*\bhighlight-if-hover-self-or-subsequent\b\s*/, '');
      });

    this.calculate(netWorthResourceAttrRow);
  }

  splitNetWorthResourceAttr(e) {
    const splitNetWorthResourceAttrButton = e.target;
    const netWorthResourceAttrRow =
      this.netWorthResourceAttrRowTargets.find(el => el.contains(splitNetWorthResourceAttrButton));
    const netWorthResourceAllocationAttrInputs =
      this.netWorthResourceAllocationAttrInputTargets
        .filter(el => netWorthResourceAttrRow.contains(el))
        .sort((el1, el2) => {
          if (el1.dataset.partyIsRepresentedParty === 'true' && el2.dataset.partyIsRepresentedParty !== 'true') {
            return -1;
          }
          if (el1.dataset.partyIsRepresentedParty !== 'true' && el2.dataset.partyIsRepresentedParty === 'true') {
            return 1;
          }
          return 0;
        });

    const amount = parseFloat(splitNetWorthResourceAttrButton.dataset.amount);
    let remainderToAllocate =
      (((amount * 100) % netWorthResourceAllocationAttrInputs.length) / 100).toFixed(2);

    netWorthResourceAllocationAttrInputs.forEach((netWorthResourceAllocationAttrInput) => {

      let amountToAllocate =
        (((amount / netWorthResourceAllocationAttrInputs.length) * 100) / 100);
      amountToAllocate = parseFloat(amountToAllocate);
      const toFixedNoRound = (n, fixed) => ~~(Math.pow(10, fixed) * n) / Math.pow(10, fixed);
      amountToAllocate = toFixedNoRound(amountToAllocate, 2);
      if (remainderToAllocate > 0) {
        amountToAllocate += 0.01;
        remainderToAllocate = parseFloat(remainderToAllocate) - 0.01;
      } else if (remainderToAllocate < 0) {
        amountToAllocate -= 0.01;
        remainderToAllocate = parseFloat(remainderToAllocate) + 0.01;
      }

      netWorthResourceAllocationAttrInput.value =
        formatCurrency(
          amountToAllocate,
          {
            style: 'decimal',
            useGrouping: false
          }
        );
      const netWorthResourceAllocationAttrCell =
        this.netWorthResourceAllocationAttrCellTargets.find(el =>
          el.contains(netWorthResourceAllocationAttrInput));
      netWorthResourceAllocationAttrCell.classList.add('highlighted');
    });
    netWorthResourceAllocationAttrInputs[0].dispatchEvent(new Event('change'));
    this.calculate(netWorthResourceAttrRow);
  }

  calculateRow(e) {
    const netWorthResourceAllocationAttrCell = e.target;
    const netWorthResourceAttrRow = netWorthResourceAllocationAttrCell.closest('.net-worth-resource-attr-row');
    this.calculate(netWorthResourceAttrRow);
  }

  calculate(netWorthResourceAttrRow) {
    this.partyTotalNetWorthResourceAllocationsAmountOutputTargets.forEach(
      (partyTotalNetWorthResourceAllocationsAmountOutput) => {
        const netWorthResourceAllocationAttrInputs =
          this.netWorthResourceAllocationAttrInputTargets
            .filter(el => el.dataset.partyId === partyTotalNetWorthResourceAllocationsAmountOutput.dataset.partyId);

        let partyTotalNetWorthResourceAllocationsTotalAmount = 0;
        netWorthResourceAllocationAttrInputs.forEach((netWorthResourceAllocationAttrInput) => {
          let amount = 0;
          if (netWorthResourceAllocationAttrInput.placeholder !== 'Term' &&
            netWorthResourceAllocationAttrInput.placeholder !== 'Unknown (Pension)' &&
            netWorthResourceAllocationAttrInput.placeholder !== 'Unknown (Not Verified)') {
            amount = parseFloat(netWorthResourceAllocationAttrInput.value);
          }
          if (isNaN(amount)) {
            return;
          }

          partyTotalNetWorthResourceAllocationsTotalAmount += amount;
        });

        partyTotalNetWorthResourceAllocationsAmountOutput.dataset.rawAmount =
          partyTotalNetWorthResourceAllocationsTotalAmount;

        partyTotalNetWorthResourceAllocationsAmountOutput.textContent =
          formatCurrency(partyTotalNetWorthResourceAllocationsTotalAmount);
      }
    );

    this.partyEqualizationPaymentAmountOutputTargets.forEach(
      (partyEqualizationPaymentOutput) => {
        const otherPartyEqualizationPaymentOutput =
          this.partyEqualizationPaymentAmountOutputTargets.find(
            el => el.dataset.partyId !== partyEqualizationPaymentOutput.dataset.partyId
          );

        const partyTotalAmount =
          this.partyTotalNetWorthResourceAllocationsAmountOutputTargets.find(
            el => el.dataset.partyId === partyEqualizationPaymentOutput.dataset.partyId
          ).dataset.rawAmount;

        const otherPartyAmount =
          this.partyTotalNetWorthResourceAllocationsAmountOutputTargets.find(
            el => el.dataset.partyId !== partyEqualizationPaymentOutput.dataset.partyId
          ).dataset.rawAmount;

        const totalDifference = partyTotalAmount - otherPartyAmount;
        const totalDifferenceRounded = Math.floor(totalDifference * 100) / 100;
        const equalizationAmount = totalDifferenceRounded / 2;

        this.equalizationPaymentAmountFieldTarget.value = Math.abs(equalizationAmount);

        let rawAmount;
        if (Math.sign(partyTotalAmount - otherPartyAmount) === 1) {
          rawAmount = -1 * equalizationAmount;
        } else {
          rawAmount = Math.abs(equalizationAmount);
        }

        let cellOutput;
        if (Math.sign(partyTotalAmount - otherPartyAmount) === 1) {
          cellOutput = formatCurrency(-1 * equalizationAmount);
        } else {
          cellOutput = formatCurrency(Math.abs(equalizationAmount));
        }

        partyEqualizationPaymentOutput.textContent = cellOutput;
        partyEqualizationPaymentOutput.dataset.rawAmount = rawAmount;

        let descriptionLabel;

        if (parseFloat(otherPartyAmount) > parseFloat(partyTotalAmount)) {
          this.equalizationPaymentPayingPartyIdFieldTarget.value = otherPartyEqualizationPaymentOutput.dataset.partyId;

          descriptionLabel =
            otherPartyEqualizationPaymentOutput.dataset.partyFirstName + ' pays ' +
            partyEqualizationPaymentOutput.dataset.partyFirstName + ' ' +
            formatCurrency(Math.abs(equalizationAmount));

          this.equalizationPaymentSummaryLabelTarget.textContent = descriptionLabel;
        } else if (partyTotalAmount === otherPartyAmount) {
          this.equalizationPaymentPayingPartyIdFieldTarget.value === null;

          this.equalizationPaymentSummaryLabelTarget.textContent = 'No equalization payment required.';
        }
      }
    );

    this.partyTotalNetWorthResourceAllocationsAmountOutputAfterEqualizationTargets.forEach(
      (partyAllocationAfterEqualization) => {
        const partyAmountBeforeEqualization =
          this.partyTotalNetWorthResourceAllocationsAmountOutputTargets.find(
            el => el.dataset.partyId === partyAllocationAfterEqualization.dataset.partyId
          ).dataset.rawAmount;

        const partyEqualizationAmount =
          this.partyEqualizationPaymentAmountOutputTargets.find(
            el => el.dataset.partyId === partyAllocationAfterEqualization.dataset.partyId
          ).dataset.rawAmount;

        const totalAmount = parseFloat(partyAmountBeforeEqualization) + parseFloat(partyEqualizationAmount);

        partyAllocationAfterEqualization.textContent = formatCurrency(totalAmount);
      }
    );

    if (netWorthResourceAttrRow !== undefined) {
      this.validateRow(netWorthResourceAttrRow);
    } else {
      this.netWorthResourceAttrRowTargets.forEach((netWorthResourceAttrRow) => {
        this.validateRow(netWorthResourceAttrRow);
      });
    }
  }

  validateRow(netWorthResourceAttrRow) {
    const netWorthResourceAttrNetWorthResourceAllocationsInvalidSumWarning =
      this.netWorthResourceAttrNetWorthResourceAllocationsInvalidSumWarningTargets
        .find(el => netWorthResourceAttrRow.contains(el));

    const netWorthResourceAttrRowHeader =
      this.netWorthResourceAttrRowHeaderTargets
        .find(el => netWorthResourceAttrRow.contains(el));


    const netWorthResourceAllocationAttrInputs =
      this.netWorthResourceAllocationAttrInputTargets.filter(el => netWorthResourceAttrRow.contains(el) &&
        el.dataset.unknown !== 'true');

    const isInvalid = this.netWorthResourceAttrRowNetWorthResourceAllocationsInvalidSum(netWorthResourceAttrRow);

    netWorthResourceAttrNetWorthResourceAllocationsInvalidSumWarning.hidden =
      !isInvalid;

    netWorthResourceAttrRowHeader.classList.toggle('invalid-allocation', isInvalid);

    netWorthResourceAttrRow.classList.toggle(
      'allocated',
      netWorthResourceAllocationAttrInputs.some((netWorthResourceAllocationAttrInput) => {
        const amount = parseInt(netWorthResourceAllocationAttrInput.value);
        if (netWorthResourceAllocationAttrInput.dataset.unknown === 'true') {
          return !isNaN(amount) && amount !== 0;
        } else {
          return true;
        }
      })
    );

    netWorthResourceAllocationAttrInputs.forEach((netWorthResourceAllocationAttrInput) => {
      const netWorthResourceAllocationAttrCell =
        this.netWorthResourceAllocationAttrCellTargets.find(el => el.contains(netWorthResourceAllocationAttrInput));
      let amount = 0;
      if (netWorthResourceAllocationAttrInput.dataset.unknown === 'false') {
        amount = parseFloat(netWorthResourceAllocationAttrInput.value);
      }

      const netWorthResourceAttrButton =
        netWorthResourceAttrRow.querySelector("[data-division-form-target='netWorthResourceAttrButton']");

      if (netWorthResourceAllocationAttrInput.classList.contains('unknown-inputs')) {
        netWorthResourceAllocationAttrInput.readOnly = true;

        if (netWorthResourceAllocationAttrInput.dataset.netWorthResourceAttr === 'encumbrance' &&
          netWorthResourceAttrButton.dataset.amount === '') {
          netWorthResourceAllocationAttrInput.parentNode.hidden = true;
        }
        if (netWorthResourceAllocationAttrInput.dataset.subcategory === 'life_insurance' &&
          netWorthResourceAttrButton.dataset.amount === '' && netWorthResourceAttrButton.textContent !== 'N/A' &&
          netWorthResourceAllocationAttrCell.classList.contains('highlighted') &&
          netWorthResourceAllocationAttrInput.dataset.unknown === 'false') {
          netWorthResourceAllocationAttrInput.placeholder = 'Term';
        }
        if (netWorthResourceAllocationAttrInput.dataset.subcategory === 'pensions_and_retirement_accounts' &&
          netWorthResourceAttrButton.dataset.amount === '' && netWorthResourceAttrButton.textContent !== 'N/A' &&
          netWorthResourceAllocationAttrCell.classList.contains('highlighted') &&
          netWorthResourceAllocationAttrInput.dataset.unknown === 'false') {
          netWorthResourceAllocationAttrInput.placeholder = 'Unknown (Pension)';
        }
        if (netWorthResourceAttrButton.dataset.amount === '' && netWorthResourceAttrButton.textContent !== 'N/A' &&
          netWorthResourceAllocationAttrCell.classList.contains('highlighted') &&
          netWorthResourceAllocationAttrInput.dataset.unknown === 'false') {
          netWorthResourceAllocationAttrInput.placeholder = 'Unknown (Not Verified)';
        }
        if (netWorthResourceAllocationAttrInput.placeholder === 'Unknown (Pension)' &&
          netWorthResourceAllocationAttrCell.classList.contains('highlighted')) {
          netWorthResourceAllocationAttrInput.value = 1;
          netWorthResourceAllocationAttrInput.readOnly = true;
          netWorthResourceAllocationAttrInput.hidden = true;
          this.showHidden(netWorthResourceAttrButton);
        }
        if (netWorthResourceAllocationAttrInput.placeholder === 'Term' &&
          netWorthResourceAllocationAttrCell.classList.contains('highlighted')) {
          netWorthResourceAllocationAttrInput.value = 1;
          netWorthResourceAllocationAttrInput.hidden = true;
          netWorthResourceAllocationAttrInput.readOnly = true;
          this.showHidden(netWorthResourceAttrButton);
        }
        if (netWorthResourceAllocationAttrInput.placeholder === 'Unknown (Not Verified)' &&
          netWorthResourceAllocationAttrCell.classList.contains('highlighted')) {
          netWorthResourceAllocationAttrInput.value = 1;
          netWorthResourceAllocationAttrInput.hidden = true;
          netWorthResourceAllocationAttrInput.readOnly = true;
          this.showHidden(netWorthResourceAttrButton);
        }
      }

      if (netWorthResourceAttrButton.textContent !== 'Term' &&
        netWorthResourceAttrButton.textContent !== 'Unknown (Pension)' &&
        netWorthResourceAttrButton.textContent !== 'Unknown (Not Verified)') {
        netWorthResourceAllocationAttrCell.classList.toggle(
          'highlighted',
          !isNaN(amount) && amount !== 0
        );
      }

      const highlightables = this.highlightableTargets.filter(el => netWorthResourceAttrRow.contains(el));
      highlightables.forEach((highlightable) => {
        applySubsequentHighlighted(highlightable, ...highlightables);
      });
    });

    this.netWorthResourceTargets.forEach((netWorthResource) => {
      netWorthResource.classList.toggle(
        'fully-allocated',
        netWorthResourceAllocationAttrInputs.some((netWorthResourceAllocationAttrInput) => {
          if (netWorthResourceAllocationAttrInput.dataset.unknown !== 'true') {
            return this.netWorthResourceFullyAllocated(netWorthResource);
          } else {
            return true;
          }

        })
      );

      netWorthResource.classList.toggle(
        'invalid-allocation',
        netWorthResourceAllocationAttrInputs.some((netWorthResourceAllocationAttrInput) => {
          if (netWorthResourceAllocationAttrInput.dataset.unknown !== 'true') {
            return this.netWorthResourceInvalidAllocation(netWorthResource);
          } else {
            return false;
          }
        })
      );
    });
  }

  // See NetWorthResource#fully_allocated?
  netWorthResourceFullyAllocated(netWorthResource) {
    const netWorthResourceAttrRows =
      this.netWorthResourceAttrRowTargets.filter(el => netWorthResource.contains(el));

    return netWorthResourceAttrRows.every((netWorthResourceAttrRow) => {
      const netWorthResourceAttrButton =
        netWorthResourceAttrRow.querySelector("[data-division-form-target='netWorthResourceAttrButton']");

      const amount =
        netWorthResourceAttrButton !== undefined ? parseFloat(netWorthResourceAttrButton.dataset.amount) : 0;
      return (
        (
          this.netWorthResourceAttrRowHasAtLeastOnePopulatedNetWorthResourceAllocationAttrInput(netWorthResourceAttrRow) ||
          netWorthResourceAttrButton.textContent === 'N/A' || amount === 0
        ) &&
        !this.netWorthResourceAttrRowNetWorthResourceAllocationsInvalidSum(netWorthResourceAttrRow)
      );
    });
  }

  netWorthResourceAttrRowHasAtLeastOnePopulatedNetWorthResourceAllocationAttrInput(netWorthResourceAttrRow) {
    const netWorthResourceAllocationAttrInputs =
      netWorthResourceAttrRow.querySelectorAll("[data-division-form-target='netWorthResourceAllocationAttrInput']");

    return Array.from(netWorthResourceAllocationAttrInputs).some((netWorthResourceAllocationAttrInput) => {
      return !isNaN(parseFloat(netWorthResourceAllocationAttrInput.value));
    });
  }

  // See NetWorthResource#fully_allocated?
  netWorthResourceInvalidAllocation(netWorthResource) {
    const netWorthResourceAttrRows =
      netWorthResource.querySelectorAll("[data-division-form-target='netWorthResourceAttrRow']");

    return Array.from(netWorthResourceAttrRows).some((netWorthResourceAttrRow) => {

      const netWorthResourceAttrButton =
        netWorthResourceAttrRow.querySelector("[data-division-form-target='netWorthResourceAttrButton']");

      if (netWorthResourceAttrButton.textContent === 'Term' ||
        netWorthResourceAttrButton.textContent === 'Unknown (Pension)' ||
        netWorthResourceAttrButton.textContent === 'Unknown (Not Verified)') {
        return false;
      } else {
        return (
          this.netWorthResourceAttrRowNetWorthResourceAllocationsInvalidSum(netWorthResourceAttrRow)
        );
      }
    });
  }

  // See NetWorthResource#attr_net_worth_resource_allocations_invalid_sum?
  netWorthResourceAttrRowNetWorthResourceAllocationsInvalidSum(netWorthResourceAttrRow) {
    if (!this.netWorthResourceAttrRowHasAtLeastOnePopulatedNetWorthResourceAllocationAttrInput(netWorthResourceAttrRow)) {
      return false;
    }

    const netWorthResourceAttrButton =
      netWorthResourceAttrRow.querySelector("[data-division-form-target='netWorthResourceAttrButton']");

    const netWorthResourceAllocationAttrInputs =
      netWorthResourceAttrRow.querySelectorAll("[data-division-form-target='netWorthResourceAllocationAttrInput']");

    const amount =
      netWorthResourceAttrButton !== undefined ? parseFloat(netWorthResourceAttrButton.dataset.amount) : 0;

    let netWorthResourceAttrNetWorthResourceAllocationsTotalAmount = 0;
    netWorthResourceAllocationAttrInputs.forEach((netWorthResourceAllocationAttrInput) => {
      const amount = parseFloat(netWorthResourceAllocationAttrInput.value);
      if (isNaN(amount)) {
        return;
      }
      netWorthResourceAttrNetWorthResourceAllocationsTotalAmount += amount;
    });

    if (netWorthResourceAttrButton.textContent === 'Term' ||
      netWorthResourceAttrButton.textContent === 'Unknown (Pension)' ||
      netWorthResourceAttrButton.textContent === 'Unknown (Not Verified)') {
      return false;
    }

    return netWorthResourceAttrNetWorthResourceAllocationsTotalAmount.toFixed(2) !== amount.toFixed(2);
  }

  showHidden(netWorthResourceAttrButton) {

    const netWorthResourceAllocationAttrInputsHidden =
      this.netWorthResourceAllocationAttrInputTargets
        .filter(el => el.dataset.unknown === 'true');

    netWorthResourceAllocationAttrInputsHidden.forEach((netWorthResourceAllocationAttrInputUnknown) => {
      const netWorthResourceAllocationAttrCell =
        this.netWorthResourceAllocationAttrCellTargets
          .find(el => el.contains(netWorthResourceAllocationAttrInputUnknown));

      if (netWorthResourceAllocationAttrCell.classList.contains('highlighted') &&
        netWorthResourceAttrButton.textContent !== 'Term' &&
        netWorthResourceAttrButton.textContent !== 'Unknown (Pension)' &&
        netWorthResourceAllocationAttrInputUnknown.placeholder !== 'Unknown (Pension)' &&
        netWorthResourceAllocationAttrInputUnknown.placeholder !== 'Term') {
        netWorthResourceAllocationAttrInputUnknown.hidden = false;
        netWorthResourceAllocationAttrInputUnknown.placeholder = 'Unknown (Not Verified)';
        netWorthResourceAllocationAttrInputUnknown.readOnly = true;
      }
      if (!netWorthResourceAllocationAttrCell.classList.contains('highlighted')) {
        netWorthResourceAllocationAttrInputUnknown.placeholder = '';
        netWorthResourceAllocationAttrInputUnknown.readOnly = true;
      }
    });




    const netWorthResourceAllocationAttrInputsHiddent =
      this.netWorthResourceAllocationAttrInputTargets
        .filter(el => el.dataset.unknownTermOrPension === 'true');



    netWorthResourceAllocationAttrInputsHiddent.forEach((netWorthResourceAllocationAttrInputUnknownt) => {
      const netWorthResourceAllocationAttrCellt =
        this.netWorthResourceAllocationAttrCellTargets
          .find(el => el.contains(netWorthResourceAllocationAttrInputUnknownt));


      if (netWorthResourceAllocationAttrCellt.classList.contains('highlighted') &&
        netWorthResourceAllocationAttrInputUnknownt.dataset.subcategory === 'pensions_and_retirement_accounts' &&
        netWorthResourceAttrButton.textContent === 'Unknown (Pension)') {
        netWorthResourceAllocationAttrInputUnknownt.hidden = false;
        netWorthResourceAllocationAttrInputUnknownt.placeholder = 'Unknown (Pension)';
        netWorthResourceAllocationAttrInputUnknownt.readOnly = true;
      }
      if (netWorthResourceAllocationAttrCellt.classList.contains('highlighted') &&
        netWorthResourceAllocationAttrInputUnknownt.dataset.subcategory === 'life_insurance' &&
        netWorthResourceAttrButton.textContent === 'Term') {
        netWorthResourceAllocationAttrInputUnknownt.hidden = false;
        netWorthResourceAllocationAttrInputUnknownt.placeholder = 'Term';
        netWorthResourceAllocationAttrInputUnknownt.readOnly = true;
      }
      if (!netWorthResourceAllocationAttrCellt.classList.contains('highlighted')) {
        netWorthResourceAllocationAttrInputUnknownt.placeholder = '';
        netWorthResourceAllocationAttrInputUnknownt.readOnly = true;
      }
    });


  }
}
