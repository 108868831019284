import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'monthlyCostInput',
    'insuranceTypeSelect',
    'includesHealth',
    'includesDental',
    'includesVision',
    'iowaCode252'
  ];

  connect() {
    this.insuranceTypeSelectTargets.forEach((insuranceTypeSelect) => {
      this.setMonthlyCostAmount(insuranceTypeSelect);
    });
    this.iowaCode252Targets.forEach((iowaCode252) => {
      this.iowaCode252Exemption(iowaCode252);
    });
    this.insuranceTypeSelectTargets.forEach((insuranceTypeSelect) => {
      this.setOppositePartyDisabledOrEnabled(insuranceTypeSelect);
    });
  }

  setMonthlyCostAmount(eventOrInsuranceTypeSelect) {
    let insuranceTypeSelect;
    if (eventOrInsuranceTypeSelect instanceof Event) {
      insuranceTypeSelect = eventOrInsuranceTypeSelect.currentTarget;
    } else {
      insuranceTypeSelect = eventOrInsuranceTypeSelect;
    }

    const insurancePrivatePayOrHawkI =
      insuranceTypeSelect.value === 'private_pay' || insuranceTypeSelect.value === 'hawk_i';

    const monthlyCostInput =
      this.monthlyCostInputTargets.find(el => el.dataset.partyId === insuranceTypeSelect.dataset.partyId);


    if (!insurancePrivatePayOrHawkI) {
      monthlyCostInput.value = '0.00';
    }

    //TitleXIX
    this.setOppositePartyDisabledOrEnabled(eventOrInsuranceTypeSelect);
    //end TitleXIX

    monthlyCostInput.disabled = !insurancePrivatePayOrHawkI;

    monthlyCostInput.dispatchEvent(new Event('input'));
    monthlyCostInput.dispatchEvent(new Event('change'));
  }

  iowaCode252Exemption(eventOrIowaCode252) {
    let iowaCode252;

    if (eventOrIowaCode252 instanceof Event) {
      iowaCode252 = eventOrIowaCode252.currentTarget;
    }
    else {
      iowaCode252 = eventOrIowaCode252;
    }

    const partyId = iowaCode252.dataset.partyId;
    const thisPartyHealth = this.includesHealthTargets.find(el => el.dataset.partyId === partyId);
    const thisPartyDental = this.includesDentalTargets.find(el => el.dataset.partyId === partyId);
    const thisPartyVision = this.includesVisionTargets.find(el => el.dataset.partyId === partyId);
    const thisPartyMonthlyCost = this.monthlyCostInputTargets.find(el => el.dataset.partyId === partyId);
    const thisPartyInsuranceTypeSelect = this.insuranceTypeSelectTargets.find(el => el.dataset.partyId === partyId);

    if (iowaCode252.disabled === true) {
      iowaCode252.checked = false;
    }
    if (iowaCode252.checked === true) {
      thisPartyHealth.checked = false;
      thisPartyDental.checked = false;
      thisPartyVision.checked = false;
      thisPartyMonthlyCost.value = 0.00;
      thisPartyHealth.disabled = true;
      thisPartyDental.disabled = true;
      thisPartyVision.disabled = true;
      thisPartyMonthlyCost.disabled = true;
      thisPartyInsuranceTypeSelect.value = null;
      thisPartyInsuranceTypeSelect.dispatchEvent(new Event('change'));
      thisPartyInsuranceTypeSelect.disabled = true;
    }
    else if (iowaCode252.checked === false) {
      thisPartyHealth.disabled = false;
      thisPartyDental.disabled = false;
      thisPartyVision.disabled = false;
      thisPartyMonthlyCost.disabled = false;
      thisPartyInsuranceTypeSelect.disabled = false;
    }
  }
  //function to enable and disable fields for title XIX
  setOppositePartyDisabledOrEnabled(eventOrInsuranceTypeSelect) {
    let changeEvent = false;
    let titlexix;
    if (eventOrInsuranceTypeSelect instanceof Event) {
      titlexix = eventOrInsuranceTypeSelect.currentTarget;
      changeEvent = true;
    } else {
      titlexix = eventOrInsuranceTypeSelect;
    }
    const insuranceTitleXIX =
      titlexix.value === 'title_xix';
    const partyId = titlexix.dataset.partyId;
    //get monthlyCostInput of opposite party
    const insuranceTypeSelectOP = this.insuranceTypeSelectTargets.find(el => el.dataset.partyId !== partyId);
    const monthlyCostInputCP = this.monthlyCostInputTargets.find(el => el.dataset.partyId === partyId);
    const monthlyCostInputOP = this.monthlyCostInputTargets.find(el => el.dataset.partyId !== partyId);
    const includesHealthOP = this.includesHealthTargets.find(el => el.dataset.partyId !== partyId);
    const includesDentalOP = this.includesDentalTargets.find(el => el.dataset.partyId !== partyId);
    const includesVisionOP = this.includesVisionTargets.find(el => el.dataset.partyId !== partyId);
    if (insuranceTitleXIX) {
      insuranceTypeSelectOP.value = null;
      insuranceTypeSelectOP.dispatchEvent(new Event('change'));
      monthlyCostInputCP.value = '0.00';
      monthlyCostInputOP.value = '0.00';
      includesHealthOP.checked = false;
      includesDentalOP.checked = false;
      includesVisionOP.checked = false;
      insuranceTypeSelectOP.disabled = insuranceTitleXIX;
      monthlyCostInputCP.disabled = insuranceTitleXIX;
      monthlyCostInputOP.disabled = insuranceTitleXIX;
      includesHealthOP.disabled = insuranceTitleXIX;
      includesDentalOP.disabled = insuranceTitleXIX;
      includesVisionOP.disabled = insuranceTitleXIX;
    }
    if(changeEvent && !insuranceTitleXIX){
      insuranceTypeSelectOP.disabled = false;
      monthlyCostInputCP.disabled = false;
      monthlyCostInputOP.disabled = false;
      includesHealthOP.disabled = false;
      includesDentalOP.disabled = false;
      includesVisionOP.disabled = false;
    }
  }
}
